import { creationSteps, creativeDimensions } from 'shared/constants/campaignmanagement';
import { adformats } from 'shared/constants/snippetbuilder';

export const RequestStatus = {
    INIT: 'INIT',
    LOADING: 'LOADING',
    DONE: 'DONE',
    ERROR: 'ERROR',
};

export const FlyoutAction = {
    NONE: 'none',
    SEND: 'send',
    SHARE: 'share',
    SEND_TO_KODDI: 'send_to_koddi',
    ADD_ORGANIZATION: 'add_organization',
    EDIT_ORGANIZATION: 'edit_organization',
    ADD_BRAND: 'add_brand',
    ADD_ACCOUNT: 'add_account',
    ORG_FILTER: 'org_filter',
};

export const RECENT_PASSWORD_ERR_CODE = 4001;

/* istanbul ignore file */

export { creationSteps, creativeDimensions, adformats };

export const DEFAULT_TIMEZONE = 'America/Chicago';

export const newFilters = [
    {
        value: 'click',
        label: 'Clicks',
        children: [
            {
                value: 'click_online',
                label: 'Online',
                children: [
                    {
                        value: 'click_units_online',
                        label: '',
                    },
                    {
                        value: 'click_revenue_online',
                        label: '',
                    },
                    {
                        value: 'click_online_roas_percent',
                        label: '',
                    },
                    {
                        value: 'click_online_roas',
                        label: '',
                    },
                ],
            },
            {
                value: 'click_instore',
                label: 'In Store',
                children: [
                    {
                        value: 'click_units_instore',
                        label: '',
                    },
                    {
                        value: 'click_revenue_instore',
                        label: '',
                    },
                    {
                        value: 'click_instore_roas_percent',
                        label: '',
                    },
                    {
                        value: 'click_instore_roas',
                        label: '',
                    },
                ],
            },
        ],
    },
    {
        value: 'view_through',
        label: 'View Through',
        children: [
            {
                value: 'view_through_online',
                label: 'Online',
                children: [
                    {
                        value: 'units_online',
                        label: '',
                    },
                    {
                        value: 'revenue_online',
                        label: '',
                    },
                    {
                        value: 'online_roas_percent',
                        label: '',
                    },
                    {
                        value: 'online_roas',
                        label: '',
                    },
                ],
            },
            {
                value: 'view_through_instore',
                label: 'In Store',
                children: [
                    {
                        value: 'units_instore',
                        label: '',
                    },
                    {
                        value: 'revenue_instore',
                        label: '',
                    },
                    {
                        value: 'instore_roas_percent',
                        label: '',
                    },
                    {
                        value: 'instore_roas',
                        label: '',
                    },
                ],
            },
        ],
    },
    {
        value: 'viewability',
        label: 'Viewability',
    },
];
