import { RequestStatus } from 'shared/constants';
import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    organizationLoadState: RequestStatus.INIT,
    updateProviderLoadState: RequestStatus.INIT,
    getProvidersLoadState: RequestStatus.INIT,
    createKoddiProviderLoadState: RequestStatus.INIT,
    accountLoadState: RequestStatus.INIT,
    orgTypeLoadState: RequestStatus.INIT,
    addOrgLoadState: RequestStatus.INIT,
    addBrandLoadState: RequestStatus.INIT,
    updateOrgLoadState: RequestStatus.INIT,
    organizationLoadError: null,
    organizations: [],
    organizationFilter: {
        keyword: '',
        includes: [
            { name: 'name', label: 'Organizations', checked: true },
            { name: 'brands', label: 'Brands', checked: true },
        ],
    },
    brandLoadState: RequestStatus.INIT,
    brandLoadError: null,
    brands: [],
    accounts: [],
    orgTypes: [],
    providers: [],
    pageNumbers: { organization: 0, brand: 0, account: 0 },
};

const organizationPageSlice = createSlice({
    name: 'organizationPage',
    initialState,
    reducers: {
        loadOrganizationListAction(draft) {
            if (draft.organizationLoadState !== RequestStatus.DONE) {
                draft.organizationLoadState = RequestStatus.LOADING;
            }
        },
        loadOrganizationListSuccessAction(draft, action) {
            draft.organizationLoadState = RequestStatus.DONE;
            draft.organizations = action.payload;
        },
        loadOrganizationListErrorAction(draft, action) {
            draft.organizationLoadState = RequestStatus.ERROR;
            draft.organizationLoadError = action.payload;
        },
        updateFilteredOrganizations(draft, action) {
            draft.organizationFilter = action.payload;
        },
        loadBrandAction(draft) {
            if (draft.brandLoadState !== RequestStatus.DONE) {
                draft.brandLoadState = RequestStatus.LOADING;
            }
        },
        loadBrandSuccessAction(draft, action) {
            draft.brandLoadState = RequestStatus.DONE;
            draft.brands = action.payload;
        },
        loadBrandErrorAction(draft, action) {
            draft.brandLoadState = RequestStatus.ERROR;
            draft.organizationLoadError = action.payload;
        },
        updateOrganizationPageNumber(draft, action) {
            draft.pageNumbers.organization = action.payload;
        },
        updateOrganizationAction(draft, action) {
            const newOrgList = draft.organizations.map((org) => {
                if (org.orgId === action.payload.orgId) {
                    return action.payload;
                }
                return org;
            });
            draft.organizations = newOrgList;
        },
        loadProvidersAction(draft) {
            if (draft.getProvidersLoadState !== RequestStatus.DONE) {
                draft.getProvidersLoadState = RequestStatus.LOADING;
            }
        },
        loadProvidersSuccessAction(draft, action) {
            draft.getProvidersLoadState = RequestStatus.DONE;
            if (action.payload) {
                const isDuplicateProvider = draft.providers.filter(
                    (provider) => action.payload.providerId === provider?.providerId,
                );
                if (isDuplicateProvider.length === 0) {
                    draft.providers = [...draft.providers, action.payload];
                }
            }
        },
        loadProvidersError(draft, action) {
            draft.getProvidersLoadState = RequestStatus.ERROR;
            draft.getProvidersLoadError = action.payload;
        },
        createKoddiProviderSuccessAction(draft, action) {
            draft.createKoddiProviderLoadState = RequestStatus.DONE;
            draft.providers = [...draft.providers, action.payload];
        },
        createKoddiProviderError(draft, action) {
            draft.createKoddiProviderLoadState = RequestStatus.ERROR;
            draft.createKoddiProviderError = action.payload;
        },
        updateKoddiProviderSuccessAction(draft, action) {
            draft.updateKoddiProviderLoadState = RequestStatus.DONE;
            draft.providers = [...draft.providers, action.payload];
        },
        updateKoddiProviderError(draft, action) {
            draft.updateKoddiProviderLoadState = RequestStatus.ERROR;
            draft.updateKoddiProviderLoadError = action.payload;
        },
        loadAccountsAction(draft) {
            if (draft.accountLoadState !== RequestStatus.DONE) {
                draft.accountLoadState = RequestStatus.LOADING;
            }
        },
        loadAccountsSuccessAction(draft, action) {
            draft.accountLoadState = RequestStatus.DONE;
            draft.accounts = action.payload;
        },
        loadAccountsErrorAction(draft, action) {
            draft.accountLoadState = RequestStatus.ERROR;
            draft.accountLoadError = action.payload;
        },
        loadOrgTypesAction(draft) {
            if (draft.orgTypeLoadState !== RequestStatus.DONE) {
                draft.orgTypeLoadState = RequestStatus.LOADING;
            }
        },
        loadOrgTypesSuccessAction(draft, action) {
            draft.orgTypeLoadState = RequestStatus.DONE;
            draft.orgTypes = action.payload;
        },
        loadOrgTypesErrorAction(draft, action) {
            draft.orgTypeLoadState = RequestStatus.ERROR;
            draft.orgTypesLoadError = action.payload;
        },
        addBrandAction(draft) {
            if (draft.addBrandLoadState !== RequestStatus.DONE) {
                draft.addBrandLoadState = RequestStatus.LOADING;
            }
        },
        addBrandSuccessAction(draft, action) {
            draft.addBrandLoadState = RequestStatus.DONE;
            draft.addedBrand = action.payload;
        },
        addBrandErrorAction(draft, action) {
            draft.addBrandLoadState = RequestStatus.ERROR;
            draft.addBrandLoadError = action.payload;
        },
        addOrgAction(draft) {
            if (draft.addOrgLoadState !== RequestStatus.DONE) {
                draft.addOrgLoadState = RequestStatus.LOADING;
            }
        },
        addOrgSuccessAction(draft, action) {
            draft.addOrgLoadState = RequestStatus.DONE;
            draft.addedOrg = action.payload;
        },
        addOrgErrorAction(draft, action) {
            draft.addOrgLoadState = RequestStatus.ERROR;
            draft.addOrgLoadError = action.payload;
        },
        updateOrgAction(draft) {
            if (draft.updateOrgLoadState !== RequestStatus.DONE) {
                draft.updateOrgLoadState = RequestStatus.LOADING;
            }
        },
        updateOrgSuccessAction(draft, action) {
            draft.updateOrgLoadState = RequestStatus.DONE;
            draft.updatedOrg = action.payload;
        },
        updateOrgErrorAction(draft, action) {
            draft.updateOrgLoadState = RequestStatus.ERROR;
            draft.updateOrgLoadError = action.payload;
        },
    },
});

export const {
    loadOrganizationListAction,
    loadOrganizationListErrorAction,
    loadOrganizationListSuccessAction,
    updateFilteredOrganizations,
    loadBrandAction,
    loadBrandSuccessAction,
    loadBrandErrorAction,
    updateOrganizationPageNumber,
    updateOrganizationAction,
    loadProvidersAction,
    loadProvidersSuccessAction,
    loadProvidersError,
    updateKoddiProviderSuccessAction,
    updateKoddiProviderError,
    createKoddiProviderSuccessAction,
    createKoddiProviderError,
    loadAccountsAction,
    loadAccountsSuccessAction,
    loadAccountsErrorAction,
    loadOrgTypesAction,
    loadOrgTypesSuccessAction,
    loadOrgTypesErrorAction,
    addOrgAction,
    addOrgSuccessAction,
    addOrgErrorAction,
    addBrandAction,
    addBrandSuccessAction,
    addBrandErrorAction,
    updateOrgAction,
    updateOrgSuccessAction,
    updateOrgErrorAction,
} = organizationPageSlice.actions;
export default organizationPageSlice.reducer;
