import request, { formatGetOptions as getOptions, formatPostOptions, formatPutOptions } from '../utils/request';
import { BASE_URL_V2 } from './api-service/Url_Constants';
import { organizationFieldsMap } from './api-service/maps';

export const fetchOrganizations = async () => {
    const options = getOptions();
    const response = await request(`${BASE_URL_V2}/accounts/ui/listOrgs`, options);
    return response.payload.map((org) => organizationFieldsMap(org));
};

export const fetchAdminOrgs = async () => {
    const options = getOptions();

    const response = await request(`${BASE_URL_V2}/accounts/ui/listOrgsAndBrands`, options);

    return response.payload.map((org) => ({
        id: org.org_id,
        name: org.organization_name,
        type: org.type,
        brands: org.brands.map((brand) => ({
            id: brand.brand_id,
            name: brand.name,
        })),
    }));
};

export const getOrgTypes = async () => {
    const options = getOptions();
    const response = await request(`${BASE_URL_V2}/accounts/org/listOrgTypes`, options);
    return response.payload.map((type) => ({
        name: type,
        value: type,
    }));
};

export const addBrand = async (values) => {
    const options = formatPostOptions(values.payload);
    const response = await request(`${BASE_URL_V2}/accounts/brand/create`, options);
    return response;
};

export const addOrg = async (values) => {
    const options = formatPostOptions({ name: values.name, org_type: values.type, state: 'Active' });
    const response = await request(`${BASE_URL_V2}/accounts/org/create`, options);
    return response;
};

export const updateOrg = async (values) => {
    const options = formatPutOptions({
        name: values.name,
        org_type: values.type,
        org_id: values.orgId,
        state: 'Active',
    });

    const response = await request(`${BASE_URL_V2}/accounts/org/update`, options);
    return response;
};
