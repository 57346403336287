import request, { formatGetOptions, formatPutOptions, formatPostOptions as postOptions } from '../utils/request';
import { BASE_URL_V2 } from './api-service/Url_Constants';

export const createKoddiProvider = async (values) => {
    const { orgId } = values.payload;
    const myadsBrandId = values.payload.myadsBrandId[0].value;

    const payload = {
        orgId,
        myadsBrandId,
        catalogBrandIds: values.payload.productCatalogBrands.map((brand) => brand.value),
    };

    const options = postOptions(payload);

    const data = await request(`${BASE_URL_V2}/accounts/koddi/advertisers/create`, options);

    return {
        providerAccountId: data.body.provider?.provider_account_id,
        orgId,
        myadsBrandId,
    };
};

export const updateKoddiProviderAccount = async (values) => {
    const { orgId, providerAccountId } = values.payload;
    const myadsBrandId = values.payload.myadsBrandId[0].value;

    const payload = {
        orgId,
        myadsBrandId,
        providerAccountId,
        catalogBrandIds: values.payload.productCatalogBrands.map((brand) => brand.value),
    };

    const options = formatPutOptions(payload);

    const data = await request(`${BASE_URL_V2}/accounts/koddi/advertisers/update`, options);

    return data;
};

export const getProviders = async (values) => {
    const { orgId } = values.payload;
    const { myadsBrandId } = values.payload;

    const options = formatGetOptions();

    const data = await request(`${BASE_URL_V2}/accounts/provider/orgs/${orgId}/brands/${myadsBrandId}`, options);

    let providerWithCatBrands;

    // eslint-disable-next-line no-restricted-syntax
    for (const provider of data?.payload.providers) {
        if (provider.provider === 'Koddi') {
            const { providerId, providerAccountId, providerAccountName } = provider;
            const { catalogBrands } = data?.payload;

            providerWithCatBrands = {
                providerId,
                providerAccountId,
                providerAccountName,
                catalogBrands,
            };

            return { ...providerWithCatBrands, orgId, myadsBrandId };
        }
    }
    return {};
};
