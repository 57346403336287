import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from '@reduxjs/toolkit';
import { makeStyles } from '@material-ui/core/styles';
import { useInjectSaga } from 'redux-injectors';
import { FlyoutAction, RequestStatus } from 'shared/constants';

import saga from './saga';
import { loadOrganizationListAction, loadBrandAction } from './slice';
import { selectOrganizations, selectOrganizationLoadState, selectBrands, selectBrandsLoadState } from './selectors';
import { OrgFlyoutContainer } from './OrgFlyoutContainer';
import { OrgTableContainer } from './OrgTableContainer';
import { OrgPageTabs } from './constants';

const useStyles = makeStyles((theme) => ({
    container: {
        width: 'auto',
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: '100%',
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },

    userLoading: {
        fontFamily: 'humanBBYDigital',
        marginTop: '15px',
    },
}));

export function OrganizationListPage({
    organizations,
    orgLoadState,
    brandLoadState,
    loadOrganizations,
    brands,
    loadBrands,
}) {
    useInjectSaga({ key: 'organizationPage', saga });

    const classes = useStyles();

    const location = window?.location;

    const queryParams = new URLSearchParams(location?.search);
    const flyoutQuery = queryParams.get('flyoutStatus');

    const [flyoutStatus, setFlyoutStatus] = useState(flyoutQuery || '');
    const [selectedItem, setSelectedItem] = useState(null);
    const [tab, setTab] = useState(OrgPageTabs.ORGANIZATIONS.key);

    useEffect(() => {
        loadOrganizations();
        loadBrands();
    }, [loadOrganizations, loadBrands]);

    const onCloseOrgFlyoutModal = () => {
        setFlyoutStatus(FlyoutAction.NONE);
    };

    const onOrgActionClick = (action) => {
        setFlyoutStatus(action);
    };

    let content = null;
    if (orgLoadState === RequestStatus.LOADING) {
        content = <div className={classes.userLoading}>Loading organization list...</div>;
    } else if (organizations) {
        content = (
            <>
                <OrgTableContainer
                    organizations={organizations}
                    brands={brands}
                    flyoutStatus={flyoutStatus}
                    setFlyoutStatus={setFlyoutStatus}
                    setSelectedItem={setSelectedItem}
                    tab={tab}
                    onTabChange={setTab}
                    onOrgActionClick={onOrgActionClick}
                />
                <OrgFlyoutContainer
                    organizations={organizations}
                    orgLoadState={orgLoadState}
                    brands={brands}
                    brandsLoading={brandLoadState !== RequestStatus.DONE}
                    flyoutStatus={flyoutStatus}
                    selectedItem={selectedItem}
                    onCloseOrgFlyoutModal={onCloseOrgFlyoutModal}
                />
            </>
        );
    } else {
        content = <div className={classes.userLoading}>Not able to load organization list</div>;
    }

    return (
        <div className={classes.container}>
            <div className={classes.userlistWrapper}>{content}</div>
        </div>
    );
}

OrganizationListPage.propTypes = {
    orgLoadState: PropTypes.string,
    brandLoadState: PropTypes.string,
    organizations: PropTypes.array.isRequired,
    brands: PropTypes.array.isRequired,
    loadBrands: PropTypes.func,
    loadOrganizations: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
    orgLoadState: selectOrganizationLoadState,
    brandLoadState: selectBrandsLoadState,
    organizations: selectOrganizations,
    brands: selectBrands,
});

// export for testing
export function mapDispatchToProps(dispatch) {
    return {
        loadOrganizations: () => dispatch(loadOrganizationListAction()),
        loadBrands: () => dispatch(loadBrandAction()),
    };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(OrganizationListPage);
